import Home from "./Home";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



const Modal = ({ url, onClose }) => {
    if (!url) return null;
  
    return (
      <div className='fixed inset-0 flex flex-col items-center justify-center w-full h-full bg-gray-800 bg-opacity-75 z-50'>
        <h1 className="bg-white md:w-3/4 text-2xl p-2 helv-med uppercase sm:w-full">Verify Card Details</h1>
        <iframe src={url} title="verify payment" className="md:w-3/4 md:h-3/4 sm:h-5/6 sm:w-full">
          
        </iframe>
        <button onClick={onClose} className="text-red-500 text-lg bg-white md:w-3/4 sm:w-full p-2 helv-med hover:text-red-800">Finished <i className="text-sm block text-black">(Don't forget to submit payment)</i></button>
      </div>
    );
  };


export default function SearchLeads({leads, user = []}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLeads, setFilteredLeads] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState('');
    const [selectedLead, setSelectedLead] = useState(null);
    const [outcome, setOutcome] = useState('');
    const [pledge, setPledge] = useState('')
    const [email, setEmail] = useState('')
    const [paymentMethod, setPaymentMethod] = useState(1);
    const [accountName, setAccountName] = useState('');
    const [agentusername, setAgentusername] = useState('')
    const [bsb, setbsb] = useState('')
    const [accountNumber, setAccountNumber] = useState()
    const [ticket, setTicket] = useState(0);
    const [orderNumber, setOrderNumber] = useState('')
    const [raffleType, setRaffleType] = useState(2);
    const [projectName, setProjectName] = useState("")
    const [donation, setDonation] = useState('')
    const [agentSent, setAgentSent] = useState(false);
    const [isNewSupplier, setIsNewSupplier] = useState(false);
    const [showLeads, setShowLeads] = useState(true);
    const [modalUrl, setModalUrl] = useState('');
    const navigate = useNavigate();
    const [charities, setCharities] = useState([])
    const [raffles, setRaffles] = useState([]);
    const [verify, setVerify] = useState(false);
    const [verified, setVerified] = useState(false)
    const [raffleName, setRaffleName] = useState('')
    const [submitDate, setSubmitDate] = useState('');



    const fetchCharities = async () => {
        try {
          const token = localStorage.getItem('token');

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/charity`, {
              Authorization: `Bearer ${token}`,
            })

            const charityData = response.data
            setCharities(charityData)

        } catch (error) {
            console.error(error)
            alert(error)
        }
    }

   

    const fetchRaffles = async () => {
      try {
        const token = localStorage.getItem('token');

          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/raffle`, {
            Authorization: `Bearer ${token}`,
          })

          const raffleDAta = response.data
          setRaffles(raffleDAta)

      } catch (error) {
          console.error(error)
          alert(error)
      }
  }

    useEffect(() => {
        fetchCharities()
        fetchRaffles()
    }, [])
  
    
      useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const dd = String(today.getDate()).padStart(2, '0');
        const hh = String(today.getHours()).padStart(2, '0');
        const min = String(today.getMinutes()).padStart(2, '0');
        const ss = String(today.getSeconds()).padStart(2, '0');
        const timeZoneOffset = -today.getTimezoneOffset();
        const formattedTimeZoneOffset = `${timeZoneOffset > 0 ? '+' : '-'}${String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0')}:${String(Math.abs(timeZoneOffset) % 60).padStart(2, '0')}`;
        const formattedDate = `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}${formattedTimeZoneOffset}`;
        setSubmitDate(formattedDate);
      }, []);
    
      const handleTimeChange = (e) => {
        const [hours, minutes] = e.target.value.split(':');
        const date = new Date(submitDate);
        date.setHours(hours);
        date.setMinutes(minutes);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const hh = String(date.getHours()).padStart(2, '0');
        const min = String(date.getMinutes()).padStart(2, '0');
        const ss = String(date.getSeconds()).padStart(2, '0');
        const timeZoneOffset = -date.getTimezoneOffset();
        const formattedTimeZoneOffset = `${timeZoneOffset > 0 ? '+' : '-'}${String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0')}:${String(Math.abs(timeZoneOffset) % 60).padStart(2, '0')}`;
        const formattedDate = `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}${formattedTimeZoneOffset}`;
        setSubmitDate(formattedDate);
      }

    const handleCloseModal = () => {
      setModalUrl(''); // Hide the modal
    };
  
    useEffect(() => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;
        setSubmitDate(formattedDate);
      }, []);  
  
    const handleSearchChange = (event) => {
      const searchTerm = event.target.value;
      setSearchTerm(searchTerm);
      setShowLeads(true)

  
      if (searchTerm === '') {
        setFilteredLeads([]);
      } else {
        const filtered = leads.filter(lead =>
          lead.supplierCustomerId.toLowerCase() === searchTerm.toLowerCase()
        );
        setFilteredLeads(filtered);
      }
    };
  
    const handleleadSelect = (leadId) => {
      setSelectedLeadId(leadId);
  
      // Find the lead with the selected ID
      const lead = leads.find(lead => lead._id === leadId);
      setShowLeads(false)
      setSelectedLead(lead);
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;

      setSelectedLead(prevState => ({
        ...prevState,
        [name]: value
      }));

      if (name === 'projectName') {
        setProjectName(value);
      }

      switch (name) {
        case 'outcome':
          setOutcome(value);
          break;
          case 'email':
          setEmail(value);
          break;
          case 'accountNumber':
            setAccountNumber(value);
        break;
        case 'accountName':
            setAccountName(value);
        break;
        
        case 'bsb':
            setbsb(value);
        break;
        case 'agentusername':
            setAgentusername(value);
        break;
          case 'pledge':
          setPledge(value);
          break;
          case 'donation':
          setDonation(value);
          break;
          case 'orderNumber':
          setOrderNumber(value);
          break;
          case 'submitDate':
          setSubmitDate(value);
          break;
          case 'ticket':
          setTicket(value);
          break;
          
          
    }
  
      setSelectedLead(prevState => ({
        ...prevState,
        [name]: name === 'paymentMethod' ||  name === 'raffleType' ? parseInt(value) : value
      }));
    };

    useEffect(() => {
    }, [selectedLead]);

    const handleAddNewSupplier = () => {
      setSelectedLead({
        ...selectedLead,
        supplierCustomerId: searchTerm, // Ensure the search term becomes the supplierCustomerId
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        suburb: '',
        state: '',
        postCode: '',
        phoneNumber: '',
        pledge: 0,
        ticket: 0,
        donation: 0,
        paymentMethod: 1,
        projectName: '',
        submitDate: '',
        outcome: '',
        agentName: '',
        raffleName: '',
        raffleType: 2,
      });
    
      setIsNewSupplier(true);
      setShowLeads(false);
      alert("Successful, now input customer's information");
    };
    
    const saveSale = async (e) => {
      e.preventDefault();

      if(raffleType === 2){
        alert("Please select the Raffle Type")
        return
      }

      if(paymentMethod === 1){
        alert("Please select the Payment Type")
        return
      }
    
      if (!agentSent && projectName !== "MCR" && projectName !== "CBRT") {
        alert('Please send to client first');
        return;
      } 

      if ( !outcome && projectName !== "MCR" && projectName !== "CBRT") {
      alert('Outcome required');
      return; // Stop further execution
    }

    setSelectedLead({
      supplierCustomerId: searchTerm,
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      suburb: '',
      state: '',
      postCode: '',
      phoneNumber: '',
      pledge: 0,
      ticket: 0,
      donation: 0,
      paymentMethod: 1,
      projectName: '',
      submitDate: '',
      outcome: '',
      agentName: `${user.username}`,
      agentusername: `${user.username}`,
      raffleName: '',
      orderNumber: '',
      raffleType: 2,
    });
    
      if (selectedLead) {
        try {
          // Check if the supplierCustomerId already exists in the database
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/check-duplicate`, {
            params: { supplierCustomerId: selectedLead.supplierCustomerId },
            withCredentials: true,
          });
    
          if (response.data.exists) {
            alert('This supplierCustomerId already exists');
            return;
          }

          
    
          const saleData = {
            supplierCustomerId: selectedLead.supplierCustomerId,
            firstName: selectedLead.firstName,
            lastName: selectedLead.lastName,
            address: selectedLead.address,
            suburb: selectedLead.suburb,
            state: selectedLead.state,
            postCode: selectedLead.postCode,
            email: selectedLead.email,
            projectName,
            phoneNumber: selectedLead.phoneNumber,
            donation: selectedLead.donation,
            ticket,
            bsb,
            accountName,
            accountNumber,
            agentName: `${user.username}`, // Ensure this is the correct user info
            supplierId: selectedLead.supplierId || "SERBIUS",
            outcome,
            paymentMethod,
            raffleType,
            pledge,
            submitDate,
            raffleName,
            orderNumber,
            agentusername: `${user.username}`,
            team: `${user.team}`,
            paid: false
          };
    
          const data = JSON.stringify(saleData);
    
          // Create HMAC using CryptoJS
          const secretKey = process.env.REACT_APP_SECRET_KEY;
          const hmac = CryptoJS.HmacSHA256(data, secretKey).toString(CryptoJS.enc.Hex);
    
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sales/newsale`, saleData, {
            headers: {
              'X-HMAC-Signature': hmac,
            },
            withCredentials: true,
          });
    
          alert('Sale Saved');
          setAgentSent(false);
          setIsNewSupplier(false)
          window.location.reload();
        } catch (err) {
          console.error(err);
          const errorMessage = err.response?.data?.message || 'An error occurred while submitting the sale information.';
          alert(errorMessage);
        }
      } else {
        alert('No lead selected or form is incomplete.');
      }
    };

    const sendAgent = (e) => {
      e.preventDefault();
      if (agentSent) {
        alert("Already sent to client! You can now save the sale.");
        return;
      }

      if(projectName === "MCR"){
        alert("Can't send MCR to client")
        return
      }
    
      if (selectedLead) {
        axios.post('https://d-debit.com.au/api/Transaction/CreateRaffleTransaction', {
          supplierCustomerId: selectedLead.supplierCustomerId, // Ensure supplierCustomerId is passed
          title: selectedLead.title,
          firstName: selectedLead.firstName,
          lastName: selectedLead.lastName,
          address: selectedLead.address,
          suburb: selectedLead.suburb,
          state: selectedLead.state,
          postCode: selectedLead.postCode,
          email: selectedLead.email,
          projectName,
          phoneNumber: selectedLead.phoneNumber,
          bsb,
          accountName,
          accountNumber,
          ticket,
          paymentMethod,
          raffleType,
          pledge,
          raffleName,
          donation: selectedLead.donation,
          submitDate,
          supplierId: selectedLead.supplierId || "SERBIUS", // Ensure supplierId is included as well
        }, {
          headers: {
            "x-api-key": "9skPsEoU0slhxFW_qJ601S7_NFNNcXdA",
          }
        })
        .then(result => {
          const tokenizeSecureUrl = result.data.tokenizeSecureUrl;
          setAgentSent(true);
          if (tokenizeSecureUrl) {
            setModalUrl(tokenizeSecureUrl); // Show modal with URL
          } else {
            alert('Lead information submitted successfully!');
          }
        })
        .catch(err => {
          console.error(err);
          alert('An error occurred while submitting the lead information.');
        });
      } else {
        alert("No lead selected or form is incomplete.");
      }
    };

    const closePayment = () => {
      setVerified(false)
    }

    const handleStripe = async () => {
      try {
          // Call the backend to create the Stripe Checkout session
          const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
              pledge,          // Example pledge amount
              raffleType,       // 1 for monthly subscription, 0 for one-time
              projectName
          });
  
          const stripe = await stripePromise;
          
          // Use the session ID to redirect to Stripe Checkout in a new tab
          const { error } = await stripe.redirectToCheckout({
              sessionId: data.sessionId,
          });
  
          if (error) {
              console.error("Stripe checkout error:", error);
              alert("Failed to redirect to checkout.");
          }
      } catch (error) {
          console.error("Error creating checkout session:", error);
          alert("Failed to create checkout session.");
      }
  };
  

  {/* RETURN ______________________________________ */}

    return (
      <div className='font-sans'>
        <form onSubmit={sendAgent}>
          <div className='container flex justify-between w-full gap-5'>
            <label className='md:text-xl sm:text-md helv-med text-left font-black p-1 w-2/4' htmlFor="leadSearch">Search Leads: </label>
            <input
              className='border-solid h-12 text-center helv text-black bg-slate-300 p-1 md:w-1/4 sm:w-2/4 rounded-lg'
              id="leadSearch"
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search Supplier Customer Id"
            />
          </div>

          {showLeads && (
            filteredLeads.length > 0 ? (
              <ul className='bg-white h-12 border-purple-800 p-1 hover:bg-purple-200 border-2 rounded-lg mt-2'>
                {filteredLeads.map(lead => (
                  <li
                    key={lead._id}
                    className='cursor-pointer p-2 hover:bg-purple-200'
                    onClick={() => handleleadSelect(lead._id)}
                  >
                    {lead.supplierCustomerId}
                  </li>
                ))}
              </ul>
            ) : (
              searchTerm && <div>
                  <div className='mt-2 text-red-500 helv'>
                    <p>No ID found in records</p>
                  </div>
                  <button type="button" onClick={handleAddNewSupplier} className="bg-serbBlue p-2 text-white rounded-lg helv-med hover:bg-blue-400">
                    Add Supplier Customer Id
                  </button>
                </div>
            )
          )}

  
          <div className='container flex flex-col gap-5 mt-5 helv'>
            <div className='container grid lg:grid-cols-5 sm:grid-cols-5 w-full text-center justify-center gap-4'>
              <div className='container lg:col-span-1 sm:col-span-1  flex w-full flex-col justify-center'>
                <label className='text-md helv-med'>Title: </label>
                <select
                  className='text-center py-1 bg-slate-200 helv' 
                  name="title"
                  value={selectedLead?.title || ''}
                  onChange={handleInputChange}
                >
                  <option value="">--Title--</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof.">Prof.</option>
                  <option value="Mr">Mr</option>
                </select>
              </div>
              <div className='container lg:col-span-2 sm:col-span-2  flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>First Name: </label>
                <input
                  className='text-center py-1 bg-slate-200 helv'
                  type="text"
                  name="firstName"
                  value={selectedLead?.firstName || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='container lg:col-span-2 sm:col-span-2  flex w-full flex-col justify-center gap-1'>
                <label className='text-md  helv-med'>Last Name: </label>
                <input
                  className='text-center py-1 bg-slate-200 helv'
                  type="text"
                  name="lastName"
                  value={selectedLead?.lastName || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
  
            <div className='container grid lg:grid-cols-6 sm:grid-cols-3 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
              <div className='ontainer flex lg:col-span-3 sm:col-span-3 w-full flex-col justify-center gap-1'>
                <label className='text-md  helv-med'>Address: </label>
                <input
                  className='text-center py-1 bg-slate-200 helv'
                  type="text"
                  name="address"
                  value={selectedLead?.address || ''}
                  onChange={handleInputChange}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Suburb: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="suburb"
                  value={selectedLead?.suburb || ''}
                  onChange={handleInputChange}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>State: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="state"
                  value={selectedLead?.state || ''}
                  onChange={handleInputChange}
                />
              </div>
  
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Postcode: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="postCode"
                  value={selectedLead?.postCode || ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
  
            {/* Project Name raffleType _______________________________________________________ */}
            {projectName === "MCR" ? (<div className='container grid lg:grid-cols-2 sm:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
              <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Email: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="email"
                  value={selectedLead?.email || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Phone Number: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="phoneNumber"
                  value={selectedLead?.phoneNumber || ''}
                  onChange={handleInputChange}
                />
              </div>
              
            </div>) : (<div className='container grid lg:grid-cols-3 sm:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
              <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Email: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="email"
                  value={selectedLead?.email || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Phone Number: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="phoneNumber"
                  value={selectedLead?.phoneNumber || ''}
                  onChange={handleInputChange}
                />
              </div>
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
              <label className='text-md helv-med'>Raffle Type: </label>
              <select
            className='text-center py-1 bg-slate-200'
            name="raffleType"
            value={raffleType}
            onChange={(e) => setRaffleType(Number(e.target.value))}
            >
             <option value={2}>--Type--</option>

            <option value={1}>VIP</option>
            <option value={0}>One Time Payment</option>

            </select>
            </div>
            </div>)}
              
            {/*Project Name tickets _______________________________________________________________ */}
            {projectName === "MCR" ? (<div className='ccontainer grid md:grid-cols-2 md:grid-rows-1 sm:grid-rows-3 sm:grid-cols-1 w-full text-center justify-center gap-4'>
            <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Price (AUD): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="number"
                name="pledge"
                value={pledge}
                onChange={(e) => setPledge(Number(e.target.value))}
                required
              />
            </div>
              
              
              <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Payment Type: </label>
                <select
                  className='text-center py-1 bg-slate-200'
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(Number(e.target.value))}
                >
                  <option value={1}>--Payment--</option>
                  <option value={0}>Card</option>
                  <option value={2}>Bank</option>
                </select>
              </div>
            </div>) : (<div className='ccontainer grid md:grid-cols-3 md:grid-rows-1 sm:grid-rows-3 sm:grid-cols-1 w-full text-center justify-center gap-4'>
            <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Price (AUD): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="number"
                name="pledge"
                value={pledge}
                onChange={(e) => setPledge(Number(e.target.value))}
                required
              />
            </div>
              
              <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Tickets (Quantity): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="number"
                name="ticket"
                value={ticket}
                onChange={(e) => setTicket(Number(e.target.value))}
              />
            </div>
              <div className='container flex lg:col-span-1 sm:col-span-1 w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Payment Type: </label>
                <select
                  className='text-center py-1 bg-slate-200'
                  name="paymentMethod"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(Number(e.target.value))}
                >
                  <option value={1}>--Payment--</option>
                  <option value={0}>Card</option>
                  <option value={2}>Bank</option>
                </select>
              </div>
            </div>)}
  
            <div>
              

          {/*Card details _____________________________________________________ */}
           {paymentMethod === 2 ? (
              <div className='container grid lg:grid-cols-3 sm:grid-cols-2 lg:grid-rows-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>
                <div className='container flex lg:col-span-1 sm:col-span-2 w-full flex-col justify-center gap-1'>
                  <label className='text-md helv-med'>Account Name: </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="accountName"
                    defaultValue=""
                    value={accountName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                  <label className='text-md helv-med'>BSB </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="bsb"
                    defaultValue=""

                    value={bsb}
                    onChange={handleInputChange}
                  />
                </div>
                <div className='container lg:col-span-1 sm:col-span-1 flex w-full flex-col justify-center'>
                  <label className='text-md helv-med'>Account Number: </label>
                  <input
                    className='text-center py-1 bg-slate-200'
                    type="text"
                    name="accountNumber"
                    defaultValue=""

                    value={accountNumber}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            ) : (
              null
            )}
           </div>

           {projectName === "MCR" ? (
            <div className='container grid md:grid-cols-3 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-3 w-full text-center justify-center gap-4'>
  
              <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Campaign Name: </label>
                <select
                      className='text-center py-1 bg-slate-200'
                      name="title"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      >
                        <option  value="">-Campaign-</option>
                      {charities.map (charity => (
                        charity.active === true && (
                          <option key={charity._id} value={charity.projectName}>{charity.name}</option>
                        )
                      )
                      )}
                </select>
              </div>
              
                <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Raffle Name: </label>
                <select
                      className='text-center py-1 bg-slate-200'
                      name="title"
                      value={raffleName}
                      onChange={(e) => setRaffleName(e.target.value)}
                      >
                      {raffles.map (raffle => (
                        <option className="text-sm" key={raffle._id} value={raffle.name}>{raffle.name}</option>
                      )
                      )}
                </select>
                </div>
  
              <div className='container md:col-span-1 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Submit Date (yyyy-mm-dd): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="text"
                name="submitDate"
                value={submitDate}
                onChange={handleInputChange}

              />
              </div>

              
            </div>
           ) : (
            <div className='container grid md:grid-cols-3 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-3 w-full text-center justify-center gap-4'>
  
              <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Campaign Name: </label>
              <select
                    className='text-center py-1 bg-slate-200'
                    name="title"
                    value={projectName}
                    onChange={(e) => {
                      const selectedProjectName = e.target.value;
                      setProjectName(selectedProjectName);
                      if (selectedProjectName === "MCR") {
                        setVerified(true);                        
                      } else {
                        setVerified(false);
                      }
                    }}
                    >
                    <option  value="">-Campaign-</option>
                    {charities.map (charity => (
                      charity.active === true && (
                      
                        <option key={charity._id} value={charity.projectName}>{charity.name}</option>
                        
                      )
                    )
                    )}
                  </select>
                </div>

                <div className='container md:col-span-1 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Submit Date (yyyy-mm-dd): </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="submitDate"
                  value={submitDate}
                  onChange={handleInputChange}

                />
                  
                </div>

                <div className='container md:col-span-1 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
                
                <label className='text-md helv-med'>Submit Time: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="time"
                  name="submitTime"
                  value={submitDate.substring(11, 16)}
                  onChange={handleTimeChange}
                />
                </div>
              </div>
           )}


  
           {/*} <div className='container grid md:grid-cols-3 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-3 w-full text-center justify-center gap-4'>
  
            <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
            <label className='text-md helv-med'>Project Name: </label>
            <select
                  className='text-center py-1 bg-slate-200'
                  name="title"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  >
                  {charities.map (charity => (
                    <option key={charity._id} value={charity.projectName}>{charity.projectName}</option>
                  )
                  )}
                </select>
              </div>

              {projectName === "MCR3" && (
                <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Raffle Name: </label>
                <select
                      className='text-center py-1 bg-slate-200'
                      name="title"
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                      >
                      {charities.map (charity => (
                        <option key={charity._id} value={charity.projectName}>{charity.projectName}</option>
                      )
                      )}
                </select>
                </div>
              )}
  
              <div className='container md:col-span-1 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
              <label className='text-md helv-med'>Submit Date (yyyy-mm-dd): </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="text"
                name="submitDate"
                value={submitDate}
                onChange={handleInputChange}

              />
                
              </div>

              <div className='container md:col-span-1 sm:col-span-1  flex w-full flex-col justify-center gap-1'>
              
              <label className='text-md helv-med'>Submit Time: </label>
              <input
                className='text-center py-1 bg-slate-200'
                type="time"
                name="submitTime"
                value={submitDate.substring(11, 16)}
                onChange={handleTimeChange}
              />
              </div>
            </div> {*/}

            <div className='container grid md:grid-cols-2 md:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2 w-full text-center justify-center gap-4'>

              {projectName === "MCR" ? (
                <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Order Number: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="orderNumber"
                  value={orderNumber}
                  onChange={handleInputChange}
                  required
                />
              </div>
              ) : (
                <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Outcome: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="outcome"
                  value={outcome}
                  onChange={handleInputChange}
                  required
                />
              </div>
              )}

              <div className='container md:col-span-1 sm:col-span-1 flex w-full flex-col justify-center gap-1'>
                <label className='text-md helv-med'>Agent Name: </label>
                <input
                  className='text-center py-1 bg-slate-200'
                  type="text"
                  name="agentName"
                  value={`${user.firstName} ${user.lastName}`}
                  onChange={handleInputChange}
                />
              </div>
            </div>
  
            <div className='container flex justify-between w-full text-center gap-4'>
              <div className='container flex w-80 col-span-1  flex-row justify-start gap-5'>
                {projectName !== "MCR" && projectName !== "CBRT" && (
                  <button
                  className='text-center  container flex items-center justify-center helv-med py-1 text-xl text-black h-16 rounded-2xl bg-slate-300 hover:bg-slate-400'
                  type="button" onClick={sendAgent}
                  
                >
                  Send to Client
                </button>
                )}
  
                { projectName === "CBRT" && (
                  <button
                  className='text-center  container flex items-center justify-center helv-med py-1 text-xl text-black h-16 rounded-2xl bg-slate-300 hover:bg-slate-400'
                  type="button" 
                  onClick={handleStripe}
                >
                  Verify Payment
                </button>
                )}
              
              </div>
              <div className='container flex w-80 flex-col justify-center gap-1'>
              <button
                  className='text-center hover:cursor-pointer container flex items-center justify-center helv-med py-1 text-xl text-white h-16 rounded-2xl bg-gradient-to-br from-blue-800 to-blue-400 hover:bg-gradient-to-lr hover:from-blue-400 hover:to-blue-800 hover:shadow-lg hover:shadow-blue-900'
                  type="button"
                  onClick={saveSale}
                  >
                  Save Sale
                </button>
              </div>
            </div>
          </div>
          
        </form>
  
        <Modal url={modalUrl} onClose={handleCloseModal} />

        {projectName === "MCR" && verified && (
          <div className="fixed min-w-full h-full bg-black bg-opacity-50 top-0 left-0 container flex justify-center items-center flex-col">
            <div className="bg-white py-10 px-5 flex flex-col justify-center items-center"> 
              <a href={`https://mycharityraffle.com.au/?agency=Serbius_${user.agentId}`} className="text-black text-2xl underline"  target="_blank" rel="noopener noreferrer">
                Go to Charity Raffle Site
              </a>
              <button onClick={closePayment} className=" text-red-500 helv-med text-right p-4" type="button">close</button>
            </div>
            
          </div>
        )}
  
      </div>
    );
}