import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function DonationStripe() {
    const [donationType, setDonationType] = useState("one-time"); // "one-time" or "monthly"
    const [amount, setAmount] = useState(""); // Custom donation amount

    const handleCheckout = async () => {
        try {
            // Call the backend to create the Stripe Checkout session with custom amount
            const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/create-checkout-session`, {
                amount, // Pass custom amount
                donationType,
            });

            const stripe = await stripePromise;
            // Redirect the user to the Stripe Checkout page
            const { error } = await stripe.redirectToCheckout({
                sessionId: data.sessionId,
            });

            if (error) {
                console.error("Stripe checkout error:", error);
                alert("Failed to redirect to checkout.");
            }
        } catch (error) {
            console.error("Error creating checkout session:", error);
            alert("Failed to create checkout session.");
        }
    };

    return (
        <div>
            <label>Donation Type:</label>
            <select onChange={(e) => setDonationType(e.target.value)} value={donationType}>
                <option value="one-time">One-time</option>
                <option value="monthly">Monthly</option>
            </select>

            <label>Amount:</label>
            <input
                type="number"
                min="1"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                placeholder="Enter donation amount"
            />

            <button onClick={handleCheckout} className="btn-primary">
                Donate ${amount} {donationType === "one-time" ? "Once" : "Monthly"}
            </button>
        </div>
    );
}
